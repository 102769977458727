import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import VueQrcode from '@chenfengyuan/vue-qrcode'

Vue.component(VueQrcode.name, VueQrcode)
Vue.use(VueClipboard)
Vue.use(ElementUI)

Vue.prototype.$http = axios
Vue.prototype.$moment = moment
axios.defaults.withCredentials = true

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://b.com'
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = 'https://b.com'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://chat.api.gljshz.com'
}

// http request 拦截器
axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token')
    const user = sessionStorage.getItem('username')
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token // 请求头加上token
    }
    if (user) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.common.username = user // 请求头加上token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器
axios.interceptors.response.use(
  response => {
    // 拦截响应，做统一处理
    if (response.data) {
      switch (response.data.meta.status) {
        case 1002:
          store.commit('logout')
          sessionStorage.removeItem('username')
          sessionStorage.removeItem('token')
          router.replace({
            path: '/login'
          })
          break
        case 401:
          store.commit('logout')
          sessionStorage.removeItem('username')
          sessionStorage.removeItem('token')
          router.replace({
            path: '/login'
          })
          break
      }
    }
    return response
  },
  // 接口错误状态处理，也就是说无响应时的处理
  error => {
    console.log(error)
    return Promise.reject(error.response.status) // 返回接口返回的错误信息
  })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="translate-users">
    <div class="page-header">
      <el-input class="search-input" placeholder="请输入翻译人员姓名" v-model="queryInfo.searchText" clearable></el-input>
      <el-button class="search-btn" type="primary" @click="getTranslateList">搜索</el-button>
    </div>
    <div class="page-body">
      <el-table :data="translateList" stripe style="width: 100%">
        <el-table-column prop="id" label="ID" width="180"></el-table-column>
        <el-table-column prop="realname" label="真实姓名" width="180"></el-table-column>
        <el-table-column prop="avatar" label="头像">
          <template slot-scope="scope">
            <el-avatar size="medium" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="language" label="擅长语言">
          <template slot-scope="scope">
            <span v-for="(item,index) in JSON.parse(scope.row.language)" :key="index" :index="index">
              <el-tag type="success" v-if="item == 1">英语</el-tag>
              <el-tag type="success" v-if="item == 2">日语</el-tag>
              <el-tag type="success" v-if="item == 3">韩语</el-tag>
              <el-tag type="success" v-if="item == 4">俄语</el-tag>
              <el-tag type="success" v-if="item == 5">法语</el-tag>
              <el-tag type="success" v-if="item == 6">德语</el-tag>
              <el-tag type="success" v-if="item == 7">西班牙语</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="warning">待审核</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">正常</el-tag>
            <el-tag v-if="scope.row.status === 3" type="danger">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="nick" label="用户昵称"></el-table-column>
        <el-table-column prop="num" label="翻译场次"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="viewItem(scope.row.id)">查看详情</el-button>
            <el-button size="mini" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <!--分页区域-->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          layout="total, prev, pager, next, jumper"
          :total="queryInfo.total">
        </el-pagination>
      </div>
      <!--查看翻译人员资料对话框-->
      <el-dialog title="详细资料" :visible.sync="viewDialogVisible" width="30%">
        <div class="user-info">
          <div class="avatar">
            <el-avatar size="large" :src="translateInfo.avatar"></el-avatar>
          </div>
          <div class="name">
            <div class="item">
              <span>真实姓名：</span>
              <p>{{ translateInfo.realname }}</p>
            </div>
            <div class="item">
              <span>昵称：</span>
              <p>{{ translateInfo.nick }}</p>
            </div>
          </div>
          <div class="language">
            <div class="title">擅长语言</div>
            <div class="list">
              <div class="item" v-for="(item,index) in translateInfo.language" :index="index" :key="index">
                <el-tag type="success" v-if="item == 1">英语</el-tag>
                <el-tag type="success" v-if="item == 2">日语</el-tag>
                <el-tag type="success" v-if="item == 3">韩语</el-tag>
                <el-tag type="success" v-if="item == 4">俄语</el-tag>
                <el-tag type="success" v-if="item == 5">法语</el-tag>
                <el-tag type="success" v-if="item == 6">德语</el-tag>
                <el-tag type="success" v-if="item == 7">西班牙语</el-tag>
              </div>
            </div>
          </div>
          <div class="certificate">
            <div class="title">技能证书</div>
            <div class="list">
              <div class="item" v-for="(item,index) in translateInfo.certificate" :index="index" :key="index">
                <el-image :z-index="9000" style="width: 100px; height: 100px" :src="item" :preview-src-list="translateInfo.certificate">
                </el-image>
              </div>
            </div>
          </div>
          <div class="introduce">
            <div class="title">自我介绍</div>
            <div class="content">
              {{ translateInfo.introduce }}
            </div>
          </div>
          <div class="status">
            <div class="title">翻译人员状态</div>
            <div class="content">
              <el-radio-group v-model="translateInfo.status" @change="changeStatus">
                <el-radio :label="1">待审核</el-radio>
                <el-radio :label="2">正常</el-radio>
                <el-radio :label="3">拒绝</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="num">
            <span>已翻译：</span>
            <p>{{ translateInfo.num }}</p>
          </div>
          <div class="creat-time">
            <span>申请时间：</span>
            <p>{{ translateInfo.creat_time }}</p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="viewDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TranslateUsers',
  data () {
    return {
      queryInfo: {
        searchText: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 15,
        // 总数据条数
        total: 0
      },
      translateList: [],
      translateInfo: {
        id: '',
        realname: '',
        nick: '',
        avatar: '',
        language: [],
        certificate: [],
        introduce: '',
        status: '',
        num: 0,
        creat_time: ''
      },
      viewDialogVisible: false,
      viewCertificateList: []
    }
  },
  created () {
    this.getTranslateList()
  },
  methods: {
    // 获取房间列表
    async getTranslateList () {
      const { data: res } = await this.$http.get('/admin/translate-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.translateList = res.data.list
        this.queryInfo.total = res.data.count
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getTranslateList()
    },
    // 时间戳格式化
    dateFormat: function (time) {
      if (time === 0) {
        return 0
      }
      return this.$moment(time).format('YYYY 年 MM 月 DD 日 HH 时 mm 分 ss 秒')
    },
    // 查看单个翻译人员详情
    async viewItem (id) {
      const { data: res } = await this.$http.get('/admin/translate/' + id)
      if (res.meta.status === 200) {
        this.translateInfo = res.data
        // 处理证书数组数据
        const ary = res.data.certificate
        const arr = ary.map(function (item, index, ary) {
          return item.url
        })
        this.translateInfo.certificate = arr
        this.viewDialogVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 直接修改翻译人员状态
    async changeStatus (value) {
      const { data: res } = await this.$http.put('/admin/translate/' + this.translateInfo.id, { status: value })
      if (res.meta.status === 200) {
        this.translateInfo.status = value
        this.getTranslateList()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 删除单个翻译人员
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/admin/translate/' + id)
      if (res.meta.status === 200) {
        this.getTranslateList()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.search-input{
  width: 300px;
}
.search-btn{
  margin-left: 12px;
}
.page-body{
  padding: 40px 0;
}
.pagination{
  padding: 20px;
  text-align: center;
}
.user-info{}
.user-info .name{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.user-info .name .item{
  display: flex;
  align-content: center;
}
.user-info .name p{
  color: #67c23a;
}
.user-info .name span{
  font-weight: bold;
}
.user-info .language{}
.user-info .language .title{
  font-weight: bold;
}
.user-info .language .list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: wrap;
}
.user-info .language .list .item{
  margin: 4px;
}
.user-info .certificate{}
.user-info .certificate .title{
  font-weight: bold;
}
.user-info .certificate .list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.user-info .certificate .list .item{
  margin: 4px;
}
.user-info .introduce{
}
.user-info .introduce .title{
  font-weight: bold;
}
.user-info .introduce .content{
  margin: 10px 4px;
  padding: 6px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 4px;
  word-wrap: break-word;
  word-break: normal;
}
.user-info .status{}
.user-info .status .title{
  font-weight: bold;
}
.user-info .status .content{
  padding: 20px 4px;
}
.user-info .status .content .el-radio-group{}
.user-info .num{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-info .num span{
  font-weight: bold;
}
.user-info .num p{
  font-size: 20px;
  font-weight: bold;
  color: #67c23a;
}
.user-info .creat-time{
  padding: 20px 0;
}
.user-info .creat-time span{
  font-weight: bold;
}
.user-info .creat-time p{
  margin-top: 4px;
}
</style>

<template>
  <div class="header">
    <el-header>
      <div class="user">
        <div class="user-info" @click="modelShow = !modelShow">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <span>Admin</span>
          <transition name="el-zoom-in-top">
            <div class="model-menu" v-show="modelShow">
              <div class="menu-item">个人资料</div>
              <div class="menu-item" @click="logout">退出登录</div>
            </div>
          </transition>
        </div>
      </div>
    </el-header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      modelShow: false
    }
  },
  created () {
  },
  methods: {
    logout () {
      sessionStorage.removeItem('username')
      sessionStorage.removeItem('token')
      // 更新store中的用户信息
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.header{}
.el-header{
  background-color: #B3C0D1;
  color: #333;
  text-align: right;
  line-height: 60px;
  width: 100%;
}
.user-info{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 30px;
}
.user-info .el-avatar{}
.user-info span {
  margin-left: 0.8rem;
  font-size: 14px;
  font-weight: bold;
}
.model-menu{
  position: absolute;
  top: 60px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 40px;
}
.model-menu .menu-item{
  line-height: 2;
  font-size: 14px;
  padding: 4px;
}
.model-menu .menu-item:hover{
  color: #409eff;
}
</style>

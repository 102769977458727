<template>
  <div class="upload">
    <el-button @click="uploadShow = true">点击上传</el-button>
    <el-dialog title="文件上传" :visible.sync="uploadShow" width="50%" :before-close="handleClose">
      <div class="upload-panel-header">
        <el-upload
          class="upload-demo"
          :action="this.$http.defaults.baseURL + '/admin/upload'"
          multiple
          :on-success="uploadSuccess"
          :show-file-list="false">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">单个文件大小建议不超过2M</div>
        </el-upload>
        <el-button class="config-btn" size="medium" type="primary" @click="configSubmit">确定</el-button>
      </div>
      <div class="upload-panel-body">
        <!--已上传文件列表-->
        <el-checkbox-group v-model="checkList" :max="multiple ? 100 : 1" size="small">
          <div class="has-uploaded-list">
            <div class="item" v-for="(item,index) in fileListData" :key="index" :index="index">
              <div class="preview-img" :style="{ 'backgroundImage':'url('+ item.url +')' }">
                <div class="file-name">{{ item.name }}</div>
                <div class="tool" @click="deleteImg(item.id)">
                  <i class="el-icon-delete"></i>
                </div>
                <div class="check">
                  <el-checkbox :label="item.url"></el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
        <!--分页-->
        <div class="pagination">
          <!--分页区域-->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            layout="total, prev, pager, next, jumper"
            :total="queryInfo.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // 所有文件列表
      fileListData: [],
      uploadShow: false,
      queryInfo: {
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 15,
        // 总数据条数
        total: 0
      },
      checkList: []
    }
  },
  created () {
    // 获取数据库已上传文件
    this.getUploadedList()
  },
  methods: {
    async getUploadedList () {
      const { data: res } = await this.$http.get('/admin/get-upload-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.fileListData = res.data.list
        this.queryInfo.total = res.data.count
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 关闭文件上传弹窗
    handleClose () {
      this.checkList = []
      this.uploadShow = false
    },
    // 文件上传成功时的钩子
    uploadSuccess (response, file, fileList) {
      this.getUploadedList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getUploadedList()
    },
    // 点击确定按钮
    configSubmit () {
      // 判断是否是多图上传，或未选择图片
      if (this.checkList.length > 0 && !this.multiple) {
        this.$emit('file-select', this.checkList[0])
      } else if (this.checkList.length > 0 && this.multiple) {
        this.$emit('file-select', this.checkList)
      } else {
        this.$emit('file-select', null)
      }
      this.checkList = []
      this.uploadShow = false
    },
    // 从服务器删除单个文件
    async deleteImg (id) {
      const { data: res } = await this.$http.delete('/admin/delete-file/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getUploadedList()
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.upload-panel-header{
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.preview-img{
  width: 140px;
  height: 140px;
  background-size: cover;
  background-color: #ffffff;
  border-radius: 6px;
  position: relative;
}
.upload-panel-body .has-uploaded-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: rgba(0,0,0,0.1);
  border-radius: 6px;
  min-height: 120px;
}
.upload-panel-body .has-uploaded-list .item{
  padding: 10px;
}
.upload-panel-body .has-uploaded-list .item .preview-img .file-name{
  font-size: 12px;
  background-color: #FFFFFF;
  line-height: 1;
  opacity: 0.6;
  padding: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 6px 6px 0 0;
}
.upload-panel-body .has-uploaded-list .item .preview-img .tool{
  position: absolute;
  bottom: 10PX;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #F56C6C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
}
.upload-panel-body .has-uploaded-list .item .preview-img .tool i{
  color: #FFFFFF;
  font-size: 14px;
}
.pagination{
  margin-top: 20px;
}
.config-btn{
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 5px;
}
.upload-panel-body .has-uploaded-list .item .check{
  width: 30px;
  height: 30px;
  background-color: #909399;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 0 6px;
}
.upload-panel-body .has-uploaded-list .item .check /deep/.el-checkbox__label{
  width: 0;
  padding: 0;
  overflow: hidden;
}
</style>

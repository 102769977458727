<template>
  <div class="room-classify">
    <div class="panel-header">
      <el-button type="primary" @click="addDialogVisible = true">添加分类</el-button>
    </div>
    <div class="panel-body">
      <el-table :data="classifyList" border :stripe="true" style="width: 100%">
        <el-table-column prop="id" label="ID" width="180"></el-table-column>
        <el-table-column prop="classify_title" label="分类名称"></el-table-column>
        <el-table-column prop="classify_ad" label="分类广告">
          <template slot-scope="scope">
            <img :src="scope.row.classify_ad" style="max-height: 120px;"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--编辑分类弹窗-->
    <el-dialog title="修改分类" :visible.sync="editDialogVisible" width="30%" :before-close="handleEditClose">
      <el-form :model="editFrom" :rules="addRules" ref="ruleForm" label-width="100px" class="add-ruleForm">
        <el-form-item label="分类名称" prop="classify_title">
          <el-input v-model="editFrom.classify_title"></el-input>
        </el-form-item>
        <el-form-item label="分类广告图" prop="classify_ad">
          <div v-if="editFrom.classify_ad" class="preview-img" :style="{ 'backgroundImage':'url('+ editFrom.classify_ad +')' }"></div>
          <!--文件上传组件 file-select子组件返回父组件的文件路径数组或单个文件路径，或NULL，multiple是否支持多图选择-->
          <Upload @file-select="fileEditSelected" :multiple="false"></Upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!--添加分类弹窗-->
    <el-dialog title="添加分类" :visible.sync="addDialogVisible" width="30%" :before-close="handleAddClose">
      <el-form :model="addFrom" :rules="addRules" ref="ruleForm" label-width="100px" class="add-ruleForm">
        <el-form-item label="分类名称" prop="classify_title">
          <el-input v-model="addFrom.classify_title"></el-input>
        </el-form-item>
        <el-form-item label="分类广告图" prop="classify_ad">
          <div v-if="addFrom.classify_ad" class="preview-img" :style="{ 'backgroundImage':'url('+ addFrom.classify_ad +')' }"></div>
          <!--文件上传组件 file-select子组件返回父组件的文件路径数组或单个文件路径，或NULL，multiple是否支持多图选择-->
          <Upload @file-select="fileSelected" :multiple="false"></Upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'RoomClassify',
  components: {
    Upload
  },
  data () {
    return {
      classifyList: [],
      addDialogVisible: false, // 添加分类弹窗显示/隐藏
      addFrom: {
        classify_title: '',
        classify_ad: ''
      },
      addRules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ]
      },
      editDialogVisible: false, // 修改分类弹窗显示/隐藏
      editFrom: {
        id: 0,
        classify_title: '',
        classify_ad: ''
      },
      uploadShow: false // 上传组件的显示隐藏
    }
  },
  created () {
    this.getClassifyData()
  },
  methods: {
    async getClassifyData () {
      const { data: res } = await this.$http.get('/admin/classify-list')
      if (res.meta.status === 200) {
        this.classifyList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async handleEdit (id) {
      const { data: res } = await this.$http.get('/admin/classify/' + id)
      if (res.meta.status === 200) {
        this.editFrom = res.data
        this.editDialogVisible = true
      } else {
        this.$message.error(res.meta.status)
      }
    },
    // 编辑提交按钮
    async editDialogSubmit () {
      const { data: res } = await this.$http.put('/admin/classify/' + this.editFrom.id, this.editFrom)
      if (res.meta.status === 200) {
        this.getClassifyData()
        this.editDialogVisible = false
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 删除单个分类
    async handleDelete (id) {
      const { data: res } = await this.$http.delete('/admin/classify/' + id)
      if (res.meta.status === 200) {
        this.getClassifyData()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 提交添加表单
    async addDialogSubmit () {
      const { data: res } = await this.$http.post('/admin/classify/1', this.addFrom)
      if (res.meta.status === 200) {
        this.getClassifyData()
        this.addDialogVisible = false
      }
    },
    handleAddClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    handleEditClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 图片上传子组件传递过来的图片路径数组
    fileSelected (fileList) {
      this.addFrom.classify_ad = fileList
    },
    // 编辑表单图片上传
    fileEditSelected (fileList) {
      this.editFrom.classify_ad = fileList
    }
  }
}
</script>

<style scoped>
  .panel-header{
    text-align: right;
    padding: 20px 0;
  }
  .preview-img{
    width: 100px;
    height: 100px;
    border-radius: 6px;
    background-size: cover;
  }
</style>

<template>
  <div class="live-room">
    <div class="page-header">
      <el-input class="search-input" placeholder="请输入房间标题" v-model="queryInfo.searchText" clearable></el-input>
      <el-button class="search-btn" type="primary" @click="getRoomList">搜索</el-button>
    </div>
    <div class="page-body">
      <el-table :data="RoomList" stripe style="width: 100%">
        <el-table-column prop="id" label="ID" width="180"></el-table-column>
        <el-table-column prop="live_title" label="房间标题" width="180"></el-table-column>
        <el-table-column prop="classify_title" label="归属分类"></el-table-column>
        <el-table-column prop="room_status" label="房间状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.room_status === 1" type="warning">待审核</el-tag>
            <el-tag v-if="scope.row.room_status === 2" type="danger">已拒绝</el-tag>
            <el-tag v-if="scope.row.room_status === 3" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="nick" label="房主"></el-table-column>
        <el-table-column prop="expire_time" label="到期时间">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.expire_time === 0" type="success">永久有效</el-tag>
            <el-tag v-else type="warning">{{ dateFormat(scope.row.expire_time) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
            <el-button size="mini" type="success" v-clipboard:copy="outLink(scope.row.unique)" v-clipboard:success="onCopy" v-clipboard:error="onError">复制外链</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <!--分页区域-->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          layout="total, prev, pager, next, jumper"
          :total="queryInfo.total">
        </el-pagination>
      </div>
      <!--修改直播房间对话框-->
      <el-dialog title="修改直播房间" :visible.sync="editLiveVisible" width="50%" @close="closeEditLiveFrom">
        <el-form :model="editFrom" :rules="editliveRules" ref="editliveRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="归属分类" prop="tid">
            <el-select v-model="editFrom.tid" placeholder="归属分类">
              <el-option v-for="item in editFrom.tidList" :key="item.id" :label="item.classify_title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房间标题" prop="live_title">
            <el-input v-model="editFrom.live_title"></el-input>
          </el-form-item>
          <el-form-item label="房间介绍" prop="live_introduce">
            <el-input type="textarea" :rows="2" placeholder="请输入房间介绍" v-model="editFrom.live_introduce"></el-input>
          </el-form-item>
          <el-form-item label="房间公告" prop="live_notice">
            <el-input type="textarea" :rows="2" placeholder="请输入房间公告" v-model="editFrom.live_notice"></el-input>
          </el-form-item>
          <el-form-item label="房间状态" prop="room_status">
            <el-radio v-model="editFrom.room_status" :label="1">待审核</el-radio>
            <el-radio v-model="editFrom.room_status" :label="2">拒绝</el-radio>
            <el-radio v-model="editFrom.room_status" :label="3">正常</el-radio>
          </el-form-item>
          <el-form-item label="到期时间" prop="expire_time">
            <el-switch @change="expireChange" v-model="editFrom.expire_status" active-text="永久" inactive-text="到时到期" :active-value="0" :inactive-value="1"></el-switch>
            <br>
            <el-date-picker value-format="timestamp" v-if="editFrom.expire_status === 1" v-model="editFrom.expire_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="视频清晰度" prop="definition">
            <el-select v-model="editFrom.definition" placeholder="请选择">
              <el-option v-for="item in definitionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="editLiveVisible = false">取 消</el-button>
        <el-button type="primary" @click="editLiveSubmit">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveRoom',
  data () {
    return {
      queryInfo: {
        searchText: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 15,
        // 总数据条数
        total: 0
      },
      RoomList: [],
      editFrom: {
        id: '',
        tid: '',
        live_title: '',
        live_introduce: '',
        live_notice: '',
        room_status: '',
        expire_time: '',
        expire_status: 0,
        tidList: [],
        definition: 0
      },
      editLiveVisible: false,
      editliveRules: {
        tid: [
          { required: true, message: '请选择归属分类', trigger: 'blur' }
        ],
        live_title: [
          { required: true, message: '请输入房间标题', trigger: 'blur' }
        ],
        live_introduce: [
          { required: true, message: '请输入房间介绍', trigger: 'blur' }
        ],
        room_status: [
          { required: true, message: '请选择房间状态', trigger: 'blur' }
        ],
        expire_time: [
          { required: true, message: '请选择到期时间', trigger: 'blur' }
        ],
        definition: [
          { required: true, message: '请选择清晰度', trigger: 'blur' }
        ]
      },
      // 视频清晰度数组
      definitionList: [
        { value: 0, label: '480P' },
        { value: 1, label: '720P' },
        { value: 2, label: '1080P' },
        { value: 3, label: '1440P' },
        { value: 4, label: '4K高清' }
      ]
    }
  },
  created () {
    this.getRoomList()
  },
  methods: {
    // 获取房间列表
    async getRoomList () {
      const { data: res } = await this.$http.get('/admin/live-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.RoomList = res.data.list
        this.queryInfo.total = res.data.count
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 点击编辑按钮
    async editItem (id) {
      const { data: res } = await this.$http.get('/admin/live/' + id)
      if (res.meta.status === 200) {
        this.editFrom.id = res.data.id
        this.editFrom.tid = res.data.tid
        this.editFrom.live_title = res.data.live_title
        this.editFrom.live_introduce = res.data.live_introduce
        this.editFrom.live_notice = res.data.live_notice
        this.editFrom.room_status = res.data.room_status
        this.editFrom.expire_time = res.data.expire_time
        this.editFrom.expire_status = res.data.expire_status
        this.editFrom.definition = res.data.definition
        // 分类信息
        this.editFrom.tidList = res.data.classify_list
        this.editLiveVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 编辑表单提交按钮
    async editLiveSubmit () {
      const { data: res } = await this.$http.put('/admin/live/' + this.editFrom.id, this.editFrom)
      if (res.meta.status === 200) {
        this.getRoomList()
        this.editLiveVisible = false
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 删除直播间
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/admin/live/' + id)
      if (res.meta.status === 200) {
        this.getRoomList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getRoomList()
    },
    // 关闭修改房间弹窗
    closeEditLiveFrom () {
      this.$refs.editliveRef.resetFields()
    },
    // 时间戳格式化
    dateFormat: function (time) {
      if (time === 0) {
        return 0
      }
      return this.$moment(time).format('YYYY 年 MM 月 DD 日 HH 时 mm 分 ss 秒')
    },
    // 到期时间swith的变化
    expireChange (value) {
      console.log(value)
      if (value === 0) {
        this.editFrom.expire_time = ''
      } else {
        this.editFrom.expire_time = (new Date()).getTime()
      }
    },
    // 复制外部链接功能
    onCopy: function (e) {
      this.$message.success('复制成功')
    },
    onError: function (e) {
      this.$message.error('复制失败')
    },
    outLink (unique) {
      return 'https://chat.gljshz.com/#/single-live/' + unique
    }
  }
}
</script>

<style scoped>
  .search-input{
    width: 300px;
  }
  .search-btn{
    margin-left: 12px;
  }
  .page-body{
    padding: 40px 0;
  }
  .pagination{
    padding: 20px;
    text-align: center;
  }
</style>

<template>
  <div class="welocme">
    <el-container>
      <LeftMenu></LeftMenu>
      <el-container class="right-container">
        <Header></Header>
        <MainView></MainView>
        <Footer></Footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LeftMenu from './common/LeftMenu'
import Header from './common/Header'
import Footer from './common/Footer'
import MainView from './common/MainView'

export default {
  name: 'Welcome',
  components: {
    LeftMenu,
    Header,
    Footer,
    MainView
  }
}
</script>

<style scoped>
.right-container{
  display: flex;
  flex-direction: column;
}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}
.el-container {
  height: 100vh;
}
</style>

<template>
  <div class="dashboard">
    <div class="card-item">
      <span>{{ online }}</span>
      <p>当前在线总人数</p>
    </div>
    <div class="card-item">
      <span>{{ meeting }}</span>
      <p>会议房间</p>
    </div>
    <div class="card-item">
      <span>{{ live }}</span>
      <p>直播房间</p>
    </div>
    <div class="card-item">
      <span>{{ translate }}</span>
      <p>翻译人员</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      online: 0,
      meeting: 0,
      live: 0,
      translate: 0
    }
  },
  created () {
    this.getBaseData()
  },
  methods: {
    async getBaseData () {
      const { data: res } = await this.$http.get('/admin/dashboard')
      if (res.meta.status === 200) {
        this.online = res.data.online
        this.meeting = res.data.meeting
        this.live = res.data.live
        this.translate = res.data.translate
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
  .dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .card-item{
    width: 48%;
    background-color: #FFFFFF;
    box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .card-item span{
    font-size: 24px;
    font-weight: bold;
  }
  .card-item p{
    margin-top: 10px;
    font-size: 14px;
    opacity: 0.8;
  }
</style>

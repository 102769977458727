<template>
  <div class="login">
    <div class="page-container">
      <div class="left">
        <div class="login-from">
          <el-form :model="loginFrom" :rules="rules" ref="loginFrom" label-width="100px" class="login-area">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="loginFrom.username"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="loginFrom.password" show-password></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 2rem">
              <el-button type="primary" @click="varify">登录</el-button>
              <el-button @click="resetForm('loginFrom')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="text-center">
          <div class="logo">
            <img src="../assets/img/logo-06.svg"/>
          </div>
          <div class="desc">
            Welcome to
            <br>
            <br>
            桂林江山会展
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TencentCaptcha from 'https://ssl.captcha.qq.com/TCaptcha.js'
export default {
  name: 'Login',
  data () {
    return {
      loginFrom: {
        username: '',
        password: '',
        Ticket: '',
        Randstr: ''
      },
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  created () {
    // window.addEventListener('storage', this.afterQRScan)
    if (window.TencentCaptcha === undefined) {
      const script = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      script.type = 'text/javascript'
      script.charset = 'UTF-8'
      script.src = 'https://ssl.captcha.qq.com/TCaptcha.js'
      head.appendChild(script)
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/login', this.loginFrom)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            // 缓存username 和 token
            sessionStorage.setItem('username', res.data.username)
            sessionStorage.setItem('token', res.data.token)
            // 更新store中的用户信息
            this.$store.commit('login', { username: res.data.username, token: res.data.token })
            this.$router.push('/welcome')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    varify () {
      const appid = '2043904950' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      // eslint-disable-next-line no-undef
      const captcha = new TencentCaptcha(appid, function (res) {
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        if (res.ret === 0) {
          that.loginFrom.Ticket = res.ticket
          that.loginFrom.Randstr = res.randstr
          // 验证码成功，传递数据给后台进行登录验证
          that.submitForm('loginFrom')
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    }
  }
}
</script>

<style scoped>
.login {
  display: block;
  align-items: center;
  background-color: hsla(216, 33%, 97%, 1);
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  color: black;
}
.page-container {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: row;
}
.page-container .left {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.page-container .left .login-from{
  padding: 2.5rem;
  width: 30rem;
}
.page-container .left .login-from .login-area{}
.page-container .left .login-from .login-area .code{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page-container .left .login-from .login-area .code img{
  height: 40px;
}
.page-container .right {
  display: flex;
  background-image: url(../assets/img/bg-06.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: hsla(244, 17%, 28%, 1);
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.page-container .right .text-center{
  width: 300px;
  margin-top: -140px;
  text-align: center;
}
.page-container .right .text-center .logo{
  margin-bottom: 2.5rem;
}
.page-container .right .text-center .logo img{}
.page-container .right .text-center .desc{
  font-weight: 700;
  line-height: 1.2;
}
</style>

<template>
  <div class="left-menu">
    <el-aside width="200px">
      <el-menu default-active="2" class="el-menu-vertical-demo" :router="true" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="index">
          <i class="el-icon-loading"></i>
          <span slot="title">数据概览</span>
        </el-menu-item>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-coordinate"></i>
            <span>房间管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="classify">
              <i class="el-icon-attract"></i>
              房间分类
            </el-menu-item>
            <el-menu-item index="live-room">
              <i class="el-icon-mic"></i>
              直播间
            </el-menu-item>
            <el-menu-item index="meeting-room">
              <i class="el-icon-connection"></i>
              会议室
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-guide"></i>
            <span>翻译管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="translate-user">
              <i class="el-icon-headset"></i>
              翻译人员
            </el-menu-item>
            <el-menu-item index="translate-record">
              <i class="el-icon-document"></i>
              邀请记录
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="front-users">
          <i class="el-icon-document"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>
        <el-menu-item index="banner">
          <i class="el-icon-money"></i>
          <span slot="title">滚动图</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {}
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
.el-aside {
  background-color: rgb(84, 92, 100);
  color: #333;
  text-align: left;
  line-height: 200px;
  height: 100%;
}
.el-menu{
  border-right: 0;
}
</style>

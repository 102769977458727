<template>
  <div class="main-view">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MainView'
}
</script>

<style scoped>
.main-view{
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}
</style>

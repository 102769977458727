import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      user: '',
      token: ''
    }
  },
  mutations: {
    login (state, userInfo) {
      state.userInfo.username = userInfo.username
      state.userInfo.token = userInfo.token
    },
    logout (state) {
      state.userInfo.username = ''
      state.userInfo.token = ''
    }
  },
  actions: {
  },
  modules: {
  }
})

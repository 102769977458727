import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Welcome from '../components/Welcome'
import Dashboard from '../components/children/Dashboard'
import RoomClassify from '../components/children/RoomClassify'
import LiveRoom from '../components/children/LiveRoom'
import MeetingRoom from '../components/children/MeetingRoom'
import TranslateUsers from '../components/children/TranslateUsers'
import TranslateRecord from '../components/children/TranslateRecord'
import FrontUsers from '../components/children/FrontUsers'
import Banner from '../components/children/Banner'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    redirect: '/welcome/index',
    children: [
      { path: 'index', component: Dashboard },
      { path: 'classify', component: RoomClassify },
      { path: 'live-room', component: LiveRoom },
      { path: 'meeting-room', component: MeetingRoom },
      { path: 'translate-user', component: TranslateUsers },
      { path: 'translate-record', component: TranslateRecord },
      { path: 'front-users', component: FrontUsers },
      { path: 'banner', component: Banner }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router

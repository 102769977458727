<template>
  <div class="record">
    <el-table :data="invitationList" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="180"></el-table-column>
      <el-table-column prop="from_nick" label="发送邀请的用户"></el-table-column>
      <el-table-column prop="to_nick" label="被邀请的用户"></el-table-column>
      <el-table-column prop="room_id" label="房间号"></el-table-column>
      <el-table-column prop="room_title" label="房间名"></el-table-column>
      <el-table-column prop="creat_time" label="邀请时间" width="260">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.creat_time) }}
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <!--分页区域-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        layout="total, prev, pager, next, jumper"
        :total="queryInfo.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TranslateRecord',
  data () {
    return {
      queryInfo: {
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 15,
        // 总数据条数
        total: 0
      },
      invitationList: []
    }
  },
  created () {
    this.getInvitationList()
  },
  methods: {
    // 获取邀请记录列表
    async getInvitationList () {
      const { data: res } = await this.$http.get('/admin/invitation-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.invitationList = res.data.list
        this.queryInfo.total = res.data.count
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getTranslateList()
    },
    // 时间戳格式化
    dateFormat: function (time) {
      if (time === 0) {
        return 0
      }
      return this.$moment(time * 1000).format('YYYY 年 MM 月 DD 日 HH 时 mm 分 ss 秒')
    }
  }
}
</script>

<style scoped>
.pagination{
  padding: 20px;
  text-align: center;
}
</style>

<template>
  <div class="front-users">
    <div class="page-header">
      <el-input class="search-input" placeholder="请输入用户名" v-model="queryInfo.searchText" clearable></el-input>
      <el-button class="search-btn" type="primary" @click="getUserList">搜索</el-button>
    </div>
    <div class="page-body">
      <el-table :data="UserList" stripe style="width: 100%">
        <el-table-column prop="id" label="ID" width="120"></el-table-column>
        <el-table-column prop="username" label="用户名" width="120"></el-table-column>
        <el-table-column prop="nick" label="昵称" width="120px"></el-table-column>
        <el-table-column prop="avatar" label="头像" width="80px">
          <template slot-scope="scope">
            <el-avatar size="large" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="60px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.gender === 1" type="success">男</el-tag>
            <el-tag v-if="scope.row.gender === 2" type="success">女</el-tag>
            <el-tag v-if="scope.row.gender === 3" type="warning">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="birthday" label="生日"></el-table-column>
        <el-table-column prop="location" label="地区"></el-table-column>
        <el-table-column prop="selfSignature" label="个性签名" width="180px"></el-table-column>
        <el-table-column prop="allowType" label="验证方式" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.allowType === 1" type="success">无需验证</el-tag>
            <el-tag v-if="scope.row.allowType === 2" type="danger">需要验证</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="locked" label="账号状态" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.locked === 1" type="success">正常</el-tag>
            <el-tag v-if="scope.row.locked === 2" type="danger">被锁定</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button size="mini" @click="viewItem(scope.row.id)">查看详情</el-button>
            <el-button size="mini" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <!--分页区域-->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          layout="total, prev, pager, next, jumper"
          :total="queryInfo.total">
        </el-pagination>
      </div>
      <!--查看用户资料对话框-->
      <el-dialog title="用户资料" :visible.sync="viewDialogVisible" width="30%">
        <div class="user-info">
          <div class="avatar">
            <el-avatar size="large" :src="userInfo.avatar"></el-avatar>
          </div>
          <div class="name">
            <div class="item">
              <span>用户名：</span>
              <p>{{ userInfo.username }}</p>
            </div>
            <div class="item">
              <span>昵称：</span>
              <p>{{ userInfo.nick }}</p>
            </div>
          </div>
          <div class="status">
            <div class="title">账号状态</div>
            <div class="content">
              <el-radio-group size="medium" text-color="#67c23a" fill="#67c23a" v-model="userInfo.locked" @change="changeStatus">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="2">锁定</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              性别
            </div>
            <div class="item-content">
              <p v-if="userInfo.gender == 1">男</p>
              <p v-if="userInfo.gender == 2">女</p>
              <p v-if="userInfo.gender == 3">未知</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              手机号
            </div>
            <div class="item-content">
              <p>{{ userInfo.phone }}</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              生日
            </div>
            <div class="item-content">
              <p>{{ userInfo.birthday }}</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              地区
            </div>
            <div class="item-content">
              <p>{{ userInfo.location }}</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              个性签名
            </div>
            <div class="item-content">
              <p>{{ userInfo.selfSignature }}</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              添加权限
            </div>
            <div class="item-content">
              <p v-if="userInfo.allowType == 1">无需验证</p>
              <p v-if="userInfo.allowType == 2">需要验证</p>
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              上次登录地区
            </div>
            <div class="item-content">
              {{ userInfo.last_area }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              上次登录IP
            </div>
            <div class="item-content">
              {{ userInfo.last_ip }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              上次登录时间
            </div>
            <div class="item-content">
              {{ userInfo.last_time }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              账号创建时间
            </div>
            <div class="item-content">
              {{ userInfo.creat_time }}
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="viewDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrontUsers',
  data () {
    return {
      queryInfo: {
        searchText: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 15,
        // 总数据条数
        total: 0
      },
      UserList: [],
      userInfo: {
        id: '',
        username: '',
        nick: '',
        avatar: '',
        gender: '',
        phone: '',
        birthday: '',
        location: '',
        selfSignature: '',
        allowType: '',
        last_area: '',
        last_ip: '',
        last_time: '',
        locked: '',
        creat_time: ''
      },
      viewDialogVisible: false
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    async getUserList () {
      const { data: res } = await this.$http.get('/admin/user-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.UserList = res.data.list
        this.queryInfo.total = res.data.count
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 查看单个用户账号详情
    async viewItem (id) {
      const { data: res } = await this.$http.get('/admin/front-user/' + id)
      if (res.meta.status === 200) {
        this.userInfo = res.data
        this.viewDialogVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 删除单个用户
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/admin/front-user/' + id)
      if (res.meta.status === 200) {
        this.getUserList()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 直接修改用户状态
    async changeStatus (value) {
      const { data: res } = await this.$http.put('/admin/front-user/' + this.userInfo.id, { locked: value })
      if (res.meta.status === 200) {
        this.userInfo.locked = value
        this.getUserList()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getRoomList()
    },
    // 时间戳格式化
    dateFormat: function (time) {
      if (time === 0) {
        return 0
      }
      return this.$moment(time).format('YYYY 年 MM 月 DD 日 HH 时 mm 分 ss 秒')
    }
  }
}
</script>

<style scoped>
.search-input{
  width: 300px;
}
.search-btn{
  margin-left: 12px;
}
.page-body{
  padding: 40px 0;
}
.pagination{
  padding: 20px;
  text-align: center;
}
.user-info{}
.user-info .name{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.user-info .name .item{
  display: flex;
  align-content: center;
}
.user-info .name p{
  color: #67c23a;
}
.user-info .name span{
  font-weight: bold;
}
.info-item{
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.info-item .item-title{
  font-weight: bold;
  margin-right: 10px;
}
.info-item .item-content{}
.info-item .item-content p{
  color: #67c23a;
}
.user-info .status{}
.user-info .status .title{
  font-weight: bold;
}
.user-info .status .content{
  padding: 16px 0;
}
</style>
